import { useQuery } from "@tanstack/react-query";
import getRestUrl from "utils/env/getRestUrl";

export default function useStatsQuery() {
  return useQuery<{
    flipPredictions: {
      numHeads: number;
      numTails: number;
    };
    flipResults: {
      numHeads: number;
      numTails: number;
    };
    losingStreaks: Array<{ streak: number; userId: string }>;
    winStreaks: Array<{ streak: number; userId: string }>;
  }>(["stats"], () => fetch(getRestUrl("getStats")).then((res) => res.json()));
}
