import joinClasses from "utils/joinClasses";
import styles from "./css/PlayFlipGameGeneric.module.css";

type Props = {
  children: any;
  fadeIn?: boolean;
  rowGap: number;
};

export default function PlayFlipGameGeneric({
  children,
  fadeIn = false,
  rowGap,
}: Props) {
  return (
    <div
      className={joinClasses(styles.container, fadeIn ? styles.fadeIn : null)}
      style={{ rowGap }}
    >
      {children}
    </div>
  );
}
