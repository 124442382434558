import ResponsiveContainer from "components/containers/ResponsiveContainer";
import ColorClass from "types/enums/ColorClass";
import TextButton from "components/buttons/TextButton";
import FontClass from "types/enums/FontClass";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import ButtonTheme from "types/enums/ButtonTheme";
import DelayRender from "components/containers/DelayRender";
import Body1 from "components/text/Body1";
import HeaderLogo from "components/headers/HeaderLogo";
import useBreakpoint from "hooks/dimensions/useBreakpoints";
import ExternalLink from "components/links/ExternalLink";
import TwitterIcon from "components/icons/TwitterIcon";
import ColorValue from "types/enums/ColorValue";
import GlobalClass from "types/enums/GlobalClass";
import { Link, useLocation } from "react-router-dom";
import TextButtonTheme from "types/enums/TextButtonTheme";
import styles from "./css/HeaderDesktop.module.css";

function LeftButtons() {
  const { pathname } = useLocation();

  return (
    <div className={styles.left}>
      <ExternalLink
        className={GlobalClass.HideText}
        href="https://twitter.com/pencilflip"
      >
        <TwitterIcon colorValue={ColorValue.Navy} />
      </ExternalLink>
      <TextButton
        buttonThemeOrColorClass={TextButtonTheme.Navy}
        fontClass={FontClass.Header2}
        href="/info"
        textDecoration={pathname === "/info" ? "underline" : undefined}
        textTransform="uppercase"
        type="link_internal"
      >
        Info
      </TextButton>
      <TextButton
        buttonThemeOrColorClass={TextButtonTheme.Navy}
        fontClass={FontClass.Header2}
        href="/stats"
        textDecoration={pathname === "/stats" ? "underline" : undefined}
        textTransform="uppercase"
        type="link_internal"
      >
        Stats
      </TextButton>
    </div>
  );
}

export default function HeaderDesktop() {
  const { isWidth1280Breakpoint } = useBreakpoint();

  return (
    <div>
      <ResponsiveContainer className={styles.container}>
        <div className={styles.row1}>
          <LeftButtons />
          <div className={styles.center}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <HeaderLogo />
            </Link>
          </div>
          <div className={styles.right}>
            <DelayRender>
              <ConnectWalletButton
                buttonTheme={ButtonTheme.Beige}
                disconnectedLabel={
                  isWidth1280Breakpoint ? "Connect" : undefined
                }
                fontClass={FontClass.Header2}
              />
            </DelayRender>
          </div>
        </div>
        <div className={styles.row2}>
          <Body1 colorClass={ColorClass.Navy} textAlign="center">
            Flip a coin to double your devnet SOL
          </Body1>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
