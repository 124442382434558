/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { ReactNode } from "react";
import ReactDOMServer from "react-dom/server";
import styles from "components/toast/css/notify.module.css";
import getEnvironment from "utils/env/getEnvironment";
import { NotificationPlacement } from "antd/es/notification/interface";

interface INotifyArgs {
  description?: ReactNode;
  duration?: number;
  message?: string;
  placement?: NotificationPlacement;
  txid?: string;
  type: "info" | "success" | "error" | "warning";
}

export default function notify({
  duration = 3,
  message,
  description,
  txid,
  type,
  placement = "bottomLeft",
}: INotifyArgs): void {
  // log for Sentry and other debug purposes
  const logLevel =
    type === "warning" ? "warn" : type === "error" ? "error" : "info";
  // eslint-disable-next-line no-console
  console[logLevel](
    `Notify: ${message ?? "<no message>"} -- ${
      typeof description === "string"
        ? description
        : // eslint-disable-next-line react/jsx-no-useless-fragment
          ReactDOMServer.renderToStaticMarkup(<>{description}</>)
    }`,
    {
      env: getEnvironment(),
      txid,
      type,
    }
  );

  notification[type]({
    className: styles.notification,
    closeIcon: <div />,
    description,
    duration,
    message,
    placement,
  });
}
