import { Component } from "react";
import styles from "components/errors/css/GlobalErrorBoundary.module.css";
import Body2 from "components/text/Body2";
import Header2 from "components/text/Header2";
import logError from "utils/analytics/logError";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import ColorClass from "types/enums/ColorClass";

type Props = {
  children: any;
};
type State = {
  hasError: boolean;
};

export default class GlobalErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logError(AnalyticsEvent.GlobalErrorBoundaryHit, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ResponsiveContainer className={styles.errorBoundary}>
          <Header2 colorClass={ColorClass.Primary} className={styles.header}>
            Oh no, this page is broken!
          </Header2>
          <Body2 colorClass={ColorClass.Primary} className={styles.body}>
            We&apos;ll call up the team so the devs can do something about this.
          </Body2>
        </ResponsiveContainer>
      );
    }

    return this.props.children;
  }
}
