import PlayFlipGameProcessingTransaction from "pages/home/PlayFlipGameProcessingTransaction";
import PlayFlipGameResults from "pages/home/PlayFlipGameResults";
import PlayFlipGameSendingTransaction from "pages/home/PlayFlipGameSendingTransaction";
import PlayFlipGameStart from "pages/home/PlayFlipGameStart";
import { PlayFlipGameContextProvider } from "context/PlayFlipGameContext";
import usePlayFlipGameContext from "hooks/contexts/usePlayFlipGameContext";
import useProcessExistingBet from "hooks/useProcessExistingBet";
import assertUnreachable from "hammyflip-shared/dist/utils/assertUnreachable";

function InsideContext() {
  const { step } = usePlayFlipGameContext();
  useProcessExistingBet();

  switch (step) {
    case "choose_bet":
      return <PlayFlipGameStart />;
    case "sending_transaction":
      return <PlayFlipGameSendingTransaction />;
    case "processing_transaction":
      return <PlayFlipGameProcessingTransaction />;
    case "results":
      return <PlayFlipGameResults />;
    default:
      return assertUnreachable(step);
  }
}

export default function PlayFlipGame() {
  return (
    <PlayFlipGameContextProvider>
      <InsideContext />
    </PlayFlipGameContextProvider>
  );
}
