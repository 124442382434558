import Environment from "hammyflip-shared/dist/types/enums/Environment";
import assertUnreachable from "hammyflip-shared/dist/utils/assertUnreachable";
import getEnvironment from "utils/env/getEnvironment";

export default function getRpcUrl(): string {
  const env = getEnvironment();
  switch (env) {
    case Environment.Development:
      // From mlimcollege account
      return "https://solana-devnet.g.alchemy.com/v2/0PtPFnNoKnEHIiSRcpCQcghlcPQjLEXf";
    case Environment.Local:
      // From mlimcollege account
      return "https://solana-devnet.g.alchemy.com/v2/_YcjbF2A_9ZcJ2WC4aW8k9p-PPPSDu_2";
    case Environment.Production:
      // From mlimcollege account. Use devnet
      return "https://solana-devnet.g.alchemy.com/v2/0PtPFnNoKnEHIiSRcpCQcghlcPQjLEXf";
    default:
      return assertUnreachable(env);
  }
}
