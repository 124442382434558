/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorClass {
  ArtistPillShadow = "colorArtistPillShadow",
  BackgroundOverlay = "colorBackgroundOverlay",
  BeigeDark = "colorBeigeDark",
  BeigeLight = "colorBeigeLight",
  BrightPurple = "colorBrightPurple",
  CardBackground = "colorCardBackground",
  ClickableAreaBackground = "colorClickableAreaBackground",
  DarkCerulean = "colorDarkCerulean",
  DarkPurple = "colorDarkPurple",
  Dimmer = "colorDimmer",
  Error = "colorError",
  FloatingElement = "colorFloatingElement",
  Ghost = "colorGhost",
  Green = "colorGreen",
  LightBlue = "colorLightBlue",
  LightPink = "colorLightPink",
  LightPurple = "colorLightPurple",
  LightPurpleHover = "colorLightPurpleHover",
  LinkWater = "colorLinkWater",
  MenuPopup = "colorMenuPopup",
  Navy = "colorNavy",
  NavyHover = "colorNavyHover",
  OverlayButton = "colorOverlayButton",
  Pink = "colorPink",
  Primary = "colorPrimary",
  ProgressBar = "colorProgressBar",
  Purple = "colorPurple",
  PurpleGradientHover = "colorPurpleGradientHover",
  Red = "colorRed",
  RedHover = "colorRedHover",
  Rust = "colorRust",
  Secondary = "colorSecondary",
  Shader = "colorShader",
  SkeletonBase = "colorSkeletonBase",
  SkeletonHighlight = "colorSkeletonHighlight",
  SpotlightOverlay = "colorSpotlightOverlay",
  Tertiary = "colorTertiary",
  WebsiteBackground = "colorWebsiteBackground",
  White = "colorWhite",
  WinterGreen = "colorWinterGreen",
  WinterGreenHover = "colorWinterGreenHover",
  Yellow = "colorYellow",
  YellowHover = "colorYellowHover",
}

export default ColorClass;
