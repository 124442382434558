import { useQuery } from "@tanstack/react-query";
import dayjs from "utils/dates/dayjsex";
import getRestUrl from "utils/env/getRestUrl";

export default function useRecentPlaysQuery() {
  return useQuery<{
    recentPlays: Array<{
      betAmount: number;
      flipsPrediction: number;
      flipsResult: number;
      id: string;
      timeCreated: string;
      txid1: string;
      txid2: string;
      user: {
        id: string;
      };
    }>;
  }>(
    ["recentPlays"],
    () =>
      fetch(getRestUrl("getRecentPlays?skip=0&take=10")).then((res) =>
        res.json()
      ),
    { refetchInterval: dayjs.duration({ seconds: 5 }).asMilliseconds() }
  );
}
