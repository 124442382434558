import BottomTabs, {
  BOTTOM_TABS_HEIGHT,
} from "components/bottom-tabs/BottomTabs";
import useIsBottomTabsWidth from "hooks/dimensions/useIsBottomTabsWidth";

type Props = {
  children: any;
  hideBottomTabs?: boolean;
};

export default function PageWithBottomTabs({
  children,
  hideBottomTabs = false,
}: Props): JSX.Element {
  const isBottomTabsWidth = useIsBottomTabsWidth();

  if (!isBottomTabsWidth || hideBottomTabs) {
    return children;
  }

  return (
    <>
      <div style={{ paddingBottom: BOTTOM_TABS_HEIGHT }}>{children}</div>
      <BottomTabs />
    </>
  );
}
