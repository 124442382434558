import { SeverityLevel } from "@sentry/react";
import AnalyticsEvent from "types/enums/AnalyticsEvent";

/**
 * NOTE: we define a severity level for all events, even if they are not logged with logError.
 *
 * This makes it so if we ever change the logging code (e.g. add logError calls for some events),
 * we will still have the correct severity levels.
 */
const SEVERITY_MAP: Record<AnalyticsEvent, SeverityLevel> = {
  [AnalyticsEvent.ButtonClick]: "info",
  [AnalyticsEvent.GlobalErrorBoundaryHit]: "fatal",
  [AnalyticsEvent.TestErrorFrontend]: "error",
  [AnalyticsEvent.TestEventFrontend]: "info",
  [AnalyticsEvent.TopLevelEvent]: "error",
};

export default function getErrorEventSeverity(
  event: AnalyticsEvent,
  _errOrMsg: Error | string
): SeverityLevel {
  return SEVERITY_MAP[event];
}
