import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import RecentPlaysRow from "components/recent-plays/RecentPlaysRow";
import Header1 from "components/text/Header1";
import ColorClass from "types/enums/ColorClass";
import dayjs from "utils/dates/dayjsex";
import shortenAddress from "utils/solana/shortenAddress";
import { Suspense } from "react";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import useRecentPlaysQuery from "hooks/queries/useRecentPlaysQuery";
import useBreakpoint from "hooks/dimensions/useBreakpoints";
import styles from "./css/RecentPlays.module.css";

function Rows() {
  const { isWidth1024Breakpoint } = useBreakpoint();
  const { data, isLoading } = useRecentPlaysQuery();

  if (isLoading) {
    return (
      <LoadingSpinner
        className={styles.loadingSpinner}
        colorValue={ColorValue.Navy}
      />
    );
  }

  return (
    <>
      {(data ?? { recentPlays: [] }).recentPlays.map((datum) => (
        <RecentPlaysRow
          amountInSol={datum.betAmount / LAMPORTS_PER_SOL}
          bettor={shortenAddress(datum.user.id, isWidth1024Breakpoint)}
          didWin={datum.flipsPrediction === datum.flipsResult}
          key={datum.id}
          time={dayjs(datum.timeCreated)}
          txid={datum.txid2}
        />
      ))}
    </>
  );
}

export default function RecentPlays() {
  return (
    <div className={styles.container}>
      <Header1
        colorClass={ColorClass.Navy}
        textAlign="center"
        textTransform="uppercase"
      >
        Recent plays
      </Header1>
      <div className={styles.rows}>
        <Suspense fallback={null}>
          <Rows />
        </Suspense>
      </div>
    </div>
  );
}
