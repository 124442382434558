import ResponsiveContainer from "components/containers/ResponsiveContainer";
import ColorClass from "types/enums/ColorClass";
import Body1 from "components/text/Body1";
import HeaderLogo from "components/headers/HeaderLogo";
import MenuIcon from "components/icons/HamburgerIcon";
import ColorValue from "types/enums/ColorValue";
import { Popover } from "antd";
import PlainButton from "components/buttons/PlainButton";
import GlobalClass from "types/enums/GlobalClass";
import TextButton from "components/buttons/TextButton";
import FontClass from "types/enums/FontClass";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import ButtonTheme from "types/enums/ButtonTheme";
import { useWallet } from "@solana/wallet-adapter-react";
import { useState } from "react";
import TwitterIcon from "components/icons/TwitterIcon";
import { Link, useLocation } from "react-router-dom";
import TextButtonTheme from "types/enums/TextButtonTheme";
import styles from "./css/HeaderMobile.module.css";

function PopoverContent({ onHidePopover }: { onHidePopover: () => void }) {
  const { pathname } = useLocation();
  const { disconnect, publicKey } = useWallet();

  return (
    <div className={styles.popoverContent}>
      {publicKey == null ? (
        <ConnectWalletButton
          buttonTheme={ButtonTheme.Beige}
          fontClass={FontClass.Header2}
        />
      ) : (
        <TextButton
          buttonThemeOrColorClass={TextButtonTheme.Navy}
          fontClass={FontClass.Header2}
          onClick={() => {
            onHidePopover();
            // Timeout makes the popover hiding UX smoother. If we don't do it,
            // the button will change from "disconnect" to "connect" as the popover is
            // fading out.
            setTimeout(() => disconnect(), 300);
          }}
          textTransform="uppercase"
        >
          Disconnect wallet
        </TextButton>
      )}
      <TextButton
        buttonThemeOrColorClass={TextButtonTheme.Navy}
        fontClass={FontClass.Header2}
        href="/info"
        textDecoration={pathname === "/info" ? "underline" : undefined}
        textTransform="uppercase"
        type="link_internal"
      >
        Info
      </TextButton>
      <TextButton
        buttonThemeOrColorClass={TextButtonTheme.Navy}
        fontClass={FontClass.Header2}
        href="/stats"
        textDecoration={pathname === "/stats" ? "underline" : undefined}
        textTransform="uppercase"
        type="link_internal"
      >
        Stats
      </TextButton>
      <TextButton
        buttonThemeOrColorClass={TextButtonTheme.Navy}
        fontClass={FontClass.Header2}
        href="https://twitter.com/pencilflip"
        icon={<TwitterIcon colorValue={ColorValue.Navy} />}
        target="_blank"
        textTransform="uppercase"
        type="link_external"
      >
        Twitter
      </TextButton>
    </div>
  );
}

export default function HeaderMobile() {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <ResponsiveContainer className={styles.container}>
        <div className={styles.row1}>
          <div className={styles.left}>
            <Popover
              placement="bottomLeft"
              content={
                <PopoverContent onHidePopover={() => setVisible(false)} />
              }
              trigger="click"
              visible={visible}
              onVisibleChange={setVisible}
            >
              <PlainButton className={GlobalClass.HideText}>
                <MenuIcon colorValue={ColorValue.Navy} />
              </PlainButton>
            </Popover>
          </div>
          <div className={styles.center}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <HeaderLogo />
            </Link>
          </div>
          <div className={styles.right}>
            <MenuIcon colorValue={ColorValue.Navy} />
          </div>
        </div>
        <div className={styles.row2}>
          <Body1 colorClass={ColorClass.Navy} textAlign="center">
            Flip a coin to double your devnet SOL
          </Body1>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
