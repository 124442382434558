import axios from "axios";
import getRestUrl from "utils/env/getRestUrl";

export default async function processFlip(txid: string): Promise<{
  didUserWinBet: boolean;
}> {
  const apiUrl = getRestUrl("processFlip");
  const results = await axios.post(apiUrl, { txid });
  return results.data;
}
