import DelayRender from "components/containers/DelayRender";
import PageBody from "components/containers/PageBody";
import RecentPlays from "components/recent-plays/RecentPlays";
import ConnectWalletOrPlay from "pages/home/ConnectWalletOrPlay";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import styles from "./css/HomePage.module.css";

export default function HomePage() {
  return (
    <PageWithHeaderAndFooter>
      <DelayRender>
        <PageBody>
          <div className={styles.body}>
            <ConnectWalletOrPlay />
            <RecentPlays />
          </div>
        </PageBody>
      </DelayRender>
    </PageWithHeaderAndFooter>
  );
}
