import PageWithBottomTabs from "components/bottom-tabs/PageWithBottomTabs";
import styles from "components/containers/css/PageWithHeaderAndFooter.module.css";
import Header from "components/headers/Header";

type Props = {
  children: any;
  hasBottomTabs?: boolean;
};

export default function PageWithHeaderAndFooter({
  children,
  hasBottomTabs = false,
}: Props): JSX.Element {
  return (
    <PageWithBottomTabs hideBottomTabs={!hasBottomTabs}>
      <div className={styles.container}>
        <Header />
        <div className={styles.body}>{children}</div>
      </div>
    </PageWithBottomTabs>
  );
}
