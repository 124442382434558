import { useWallet } from "@solana/wallet-adapter-react";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import PlayFlipGame from "pages/home/PlayFlipGame";
import useBreakpoint from "hooks/dimensions/useBreakpoints";
import styles from "./css/ConnectWalletOrPlay.module.css";

export default function ConnectWalletOrPlay() {
  const { publicKey } = useWallet();
  const { isWidth640Breakpoint } = useBreakpoint();

  if (publicKey == null) {
    return (
      <div className={styles.container}>
        <img className={styles.image} src="/images/coins.gif" />
        <ConnectWalletButton
          buttonTheme={ButtonTheme.WinterGreen}
          fontClass={FontClass.Header1}
          width={isWidth640Breakpoint ? 320 : 380}
        />
      </div>
    );
  }

  return <PlayFlipGame />;
}
