import PlayFlipGameGeneric from "pages/home/PlayFlipGameGeneric";
import Header1 from "components/text/Header1";
import ColorClass from "types/enums/ColorClass";

export default function PlayFlipGameSendingTransaction() {
  return (
    <PlayFlipGameGeneric rowGap={36}>
      <img height={377} src="/images/sending-transaction.gif" width={377} />
      <Header1
        colorClass={ColorClass.Navy}
        textAlign="center"
        textTransform="uppercase"
      >
        Sending deposit to Hammy...
      </Header1>
    </PlayFlipGameGeneric>
  );
}
