import Page404 from "pages/errors/Page404";
import HomePage from "pages/home/HomePage";
import InfoPage from "pages/info/InfoPage";
import StatsPage from "pages/stats/StatsPage";
import { Route, Routes as RoutesImport } from "react-router-dom";

export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<HomePage />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/stats" element={<StatsPage />} />
      <Route path="*" element={<Page404 />} />
    </RoutesImport>
  );
}
